import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoEmbed from "../components/video-embed"
import Sidebar from "../components/sidebar"

export default class About extends React.Component {
  render() {
    return (
      <Layout title="About">
        <Seo title="What is Hope for Native America?"/>
        <div className="container">
          <div className="row">
            <section className="col-sm-9">
              <VideoEmbed vimeoId="231554950" title="What is Hope for Native America?" />
              <VideoEmbed vimeoId="275902749" title="The story of the warriors" />
              
              <p>Hope for Native America helps God's people advocate for and empower young Native Americans who are bringing hope to their people.</p>

              <p>Native America is in crisis. They are a people who are devastated by a history that has left broken families, abuse, suicide, and despair. In addition, generations of Native Americans have known little to nothing about the hope of Jesus. What's making this tragedy worse is the fact that most North American believers know little to nothing about this crisis "on their doorstep."</p>

              <p>But, hope is rising in this generation! Native young people who have been transformed by the hope they found in Jesus are carrying that hope to Native communities across North America. As they share that hope, Native Americans realize Jesus is not only "the white man's God," but the Savior they can trust.</p>

              <p>Native Americans from over 100 tribes gather every year for a life-changing conference called Warrior Leadership Summit. More and more young Native believers are preparing to be leaders for their people. This is made possible through Bible school scholarships, crisis counseling, leadership retreats and by participating in consistent communities of fellow believers. Hope for Native America is helping the most powerful message be communicated by the most effective messengers to thousands that are ready for Jesus.</p>

              <p>Imagine a generation of Native American young people defined by hope and not despair, by love and not anger, by life and not endless funerals of loved ones. A generation that is defined by the idea that they are valuable, that they matter, and that they have a future. That very generation is being raised up now.</p>

              <p>But, with new believers comes new challenges. They need spiritual mentors and healthy Christian communities that exhibit what a life led by Jesus looks like. Praise God those communities and disciples are being raised up. But, there is so much more work to be done.</p>

              <p>Hope for Native America is a part of Ron Hutchcraft Ministries, which started 25 years ago. RHM was birthed out of a desire to bring the Good News of Jesus to the lost and hard to reach. It is meant for those that might not respond to conventional methods of sharing Christ's message. This heart for people who are spiritually searching led widely-respected radio host and cross-cultural ministry leader Ron Hutchcraft, to begin a ministry working with Native Americans who could share the Gospel with their own people.</p>

              <p>That ministry, On Eagles' Wings, has been reaching thousands of Native Americans with the Gospel for a quarter-century. On Eagles’ Wings has contributed to communities on numerous Native American and First Nation reservations by bringing the Gospel to hundreds of tribes, raising up and supporting local Native-led ministries, and providing scholarships to Bible schools and seminaries. Ron Hutchcraft Ministries has now launched Hope for Native America to provide a way for God’s people to get involved with bringing the hope of Jesus to Native communities.</p>

              <p>When you choose to be Hope for Native America, you make it possible for the Gospel to be brought to thousands of Native lives.</p>

              <p>By empowering young Native Americans to be the messengers of Christ’s hope to their people, you are helping break through the tragic misconception of the “white man’s God.” By advocating for Native American leaders, you are helping to break through to the need.</p>

              <p>You can be Hope for Native America by looking at our Get Involved options. You can also be Hope for Native America by communicating to your friends, family, and church about how God is impacting the crisis in Native America, through young Native Americans who are giving their people a chance at Jesus. Get involved today and bring hope to Native America by being Hope for Native America!</p>
            </section>
            <aside className="col-sm-3">
              <h2><span>Here's how <strong>you</strong> can</span><br/><em>get involved!</em></h2>
              <Sidebar title="Equip a Leader" 
                      description={`Equip young Native Christian leaders to reach their communities for Jesus and to be a living example of hope to broken young people.`}
                      url="/get-involved/equip-a-leader"
                      thumbnail="/video-thumbnails/equip-a-leader.jpg" />
            </aside>
          </div>
        </div>
      </Layout>
    )
  }
}

